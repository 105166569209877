import { render, staticRenderFns } from "./PaChiefDeptRevenue.vue?vue&type=template&id=1065709e&scoped=true"
import script from "./PaChiefDeptRevenue.vue?vue&type=script&lang=js"
export * from "./PaChiefDeptRevenue.vue?vue&type=script&lang=js"
import style0 from "./PaChiefDeptRevenue.vue?vue&type=style&index=0&id=1065709e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1065709e",
  null
  
)

export default component.exports