<template>
  <div id='viewBox'>

    <div style='display: flex;flex-direction: row;gap: 10px;justify-content: space-between;margin-bottom: 5px'>
      <div style='display: flex;flex-direction: row;gap: 10px;justify-content: flex-start'>
        <div style='line-height: 20px;'>
          <span style='color: #ff3176'>营收绩效目标</span>
          <span style='color: #1a1a1a;'>与完成情况</span>
          <span style='color: #ff3176'>【{{ searchCondition.dept_name || '-' }}】</span>
        </div>
      </div>

      <!--      <el-link icon='el-icon-refresh' @click='getList'>刷新</el-link>-->
      <div style='display: flex;flex-direction: row;justify-content: right;margin-bottom: 2px;align-items: center'>
        <div style='display: inline-block;'>
          <LogPanel ref='refLogPanel' module-name='pa_chief_revenues' v-if='hasAuthority("pa_chief_log")'></LogPanel>
        </div>
        <div class='summary-panel'>
          共计 <span class='summary-num'>{{ countKol }}</span>个考核红人 <span class='summary-num'>{{ dataList.length
          }}</span>
          考核项
        </div>
      </div>
    </div>

    <div class='default-table'
         v-loading='loadingStatus'
         :element-loading-text='loadingText'
         element-loading-spinner='el-icon-loading'>
      <u-table
        :key='`pa_chief_revenue_${editMode?"edit":"view"}`'
        :data='tblData'
        :height='tableHeight'
        :row-height='rowHeight'
        use-virtual
        :excess-rows='30'
        :span-method='objectSpanMethod'
        @row-contextmenu='rowContextmenu'
        @header-contextmenu='headerContextmenu'
        :cell-class-name='cellClassName'
        style='width: 100%'
        border>
        <u-table-column
          prop='nickname'
          label='红人昵称'
          align='center'
          min-width='100'>
          <template slot-scope='{row}'>
            <artist-nickname :info='row' />
            <!--            <span>{{ row.dept_name || '' }}</span>-->
          </template>
        </u-table-column>
        <u-table-column
          prop='platform_name'
          label='平台'
          align='center'
          min-width='80'>
          <template slot-scope='{row}'>
            <span>{{ row.platform_name || '' }}</span>
          </template>
        </u-table-column>
        <u-table-column
          prop='income_type'
          label='收入分类'
          align='center'
          min-width='90'>
        </u-table-column>
        <u-table-column
          prop='average_value'
          label='上季月营收均值'
          min-width='110' header-align='center' align='center'>
          <template slot-scope='scope'>
            <span>{{ valFormat(scope.row[`average_value`]) }}</span>
          </template>
        </u-table-column>
        <u-table-column v-for='(month,index) in quarterlyMonths' :key='index' :label='`${month}月`'
                        align='center'>
          <u-table-column
            :prop='`quotient_${month}`'
            label='系数'
            min-width='100' header-align='center' align='center'>
            <editable-cell
              v-if='editMode'
              :show-input='row.editMode'
              slot-scope='{$index,row,column}'
              v-model='row[column.property]'
              @change='editVal(row,column.property,$index)' :is-input-number='false'>
                          <span slot='content'>
                            <span>{{ row[column.property] || 0 }}</span>
                             <i class='el-icon-edit-outline default-edit-icon'></i>
                          </span>
            </editable-cell>
            <template slot-scope='{row,column}' v-else>
              <span>{{ row[column.property] || 0 }}</span>
            </template>
          </u-table-column>
          <u-table-column
            :prop='`flag_value_${month}`'
            label='目标'
            min-width='100' header-align='center' align='center'>
            <editable-cell
              v-if='editMode'
              :show-input='row.editMode'
              slot-scope='{$index,row,column}'
              v-model='row[column.property]'
              @change='editVal(row,column.property,$index)' :is-input-number='false'>
                          <span slot='content'>
                            <span>{{ row[column.property] || 0 }}</span>
                             <i class='el-icon-edit-outline default-edit-icon'></i>
                          </span>
            </editable-cell>
            <template slot-scope='{row,column}' v-else>
              <span>{{ row[column.property] || 0 }}</span>
            </template>
          </u-table-column>
          <u-table-column
            :prop='`real_value_${month}`'
            align='center'
            header-align='center' label='完成' min-width='100'>
            <template slot-scope='{row,column}'>
              <span>{{ row[column.property] || 0 }}</span>
            </template>
          </u-table-column>
        </u-table-column>

      </u-table>
      <div class='flex-sb' style='margin-top: 10px;'>
        <div class='summary-panel'>
          <div>
            <span class='summary-title'>考核红人：</span> <span class='summary-num'>{{ countKol }}</span> 个 &nbsp;&nbsp;
            <span class='summary-title'>考核项：</span> <span class='summary-num'>{{ dataList.length }}</span> 项 &nbsp;&nbsp;
            <span class='summary-title'> 营收目标：</span><span
            class='summary-num money'>{{ $utils.numberFormat(sumFlagVal, 2)
            }}</span> &nbsp;&nbsp;
            <span class='summary-title'> 完成值：</span><span class='summary-num money'>{{ $utils.numberFormat(sumRealVal, 2)
            }}</span> &nbsp;&nbsp;
          </div>
        </div>
        <el-button
                   v-if='searchCondition.release_status==="N" &&userPermissions.indexOf("pa_chief_publish")>-1'
                   type='primary' size='mini' @click='saveConfirm'
                   :disabled='commitLoading' :loading='commitLoading'>
          确认发布
        </el-button>
      </div>

    </div>

    <div class='edit-box' style='width: 900px;margin-bottom: 10px'>
      <!--           总监绩效-->
      <EditPaChief ref='editPaChief' :sum-flag-val='sumFlagVal' :sum-real-val='sumRealVal' />
    </div>

    <!--      批量设置营收目标-->

    <BatchSetPaRevenue ref='refBatchSetPaRevenue' type-range='chief' :map='batchMap.revenue' />


    <QuotientEdit ref='refPaQuotientEdit' @savedEvent='updateQuotientCol'></QuotientEdit>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { numberFormat } from '@/utils/utils'
import EditableCell from '@/components/EditableCell'
import EditPaChief from '@/pages/pa/chief/EditPaChief'
import LogPanel from '@/components/logs/LogPanel'
import BatchSetPaRevenue from '@/pages/pa/components/BatchSetPaRevenue'
import moment from 'moment'
import QuotientEdit from '@/pages/pa/components/QuotientEdit'

export default {
  name: 'PaChiefDeptRevenue',
  components: { QuotientEdit, BatchSetPaRevenue, LogPanel, EditPaChief, EditableCell },
  data() {
    return {
      tableHeight: 0,
      rowHeight: 30,
      searchCondition: { year: null, quarter: moment().quarter(), dept_id: null, dept_name: null, release_status: 'Y' },
      activeName: 'revenue',
      activeMap: { 'trend': '涨粉', 'revenue': '营收' },
      showIndex: false,//显示序号
      loadingStatus: false,
      showEditIcon: true,
      commitLoading: false,
      edit_mode: 'add',
      // searchCondition: {
      //   months: []
      // },
      bonus_items: [],//团队奖金
      dataList: [],
      mergeFields: ['nickname', 'platform_name'],//合并的字段
      spanObj: {},//合并的数据坐标Map
      positionObj: {},//合并的位置记录
      fixFields: ['group_name', 'platform_name', 'income_type', 'average_value'],
      prefixFields: ['quotient_', 'flag_value_', 'real_value_'],
      calcFields: [],
      bonusRow: { nickname: '团队奖金', platform_name: '团队奖金', income_type: '团队奖金', average_value: '团队奖金' },//团队奖金行的可编辑列
      currentRow: {},
      currentColumn: {},
      dialogQuotientVisible: false,
      formQuotient: {
        quotient: 0,
        month: 0,
        year: 0,
        group_id: ''
      },
      mode: 'drawer',
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        // direction: 'right',
        title: '',
        width_height: '90%',
        show_footer: false
        // mask: false,
        // close_name: '关 闭',
        // confirm_name: '打 印',
      },
      logSetting: {
        visible: false
      }, performanceSetting: {
        visible: false
      },
      checkMonthList: [],
      batchMap: {
        trend: [],
        revenue: []
      }
    }
  },
  props: {
    editMode: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    groupName() {
      return this.searchCondition.group_name
    },
    loadingText() {
      return `【${this.searchCondition.year}】年第【${this.searchCondition.quarter}】季度的营收绩效目标数据加载中...`
    },
    quarterlyMonths() {
      const quarter = this.searchCondition.quarter

      return [quarter * 3 - 2, quarter * 3 - 1, quarter * 3]
    },
    tblData() {
      return this.dataList || []
    },
    // 红人统计
    countKol() {
      let kolArr = []
      this.dataList.forEach((item) => {
        if (item.artist_id) {
          if (kolArr.indexOf(item.artist_id) === -1) {

            kolArr.push(item.artist_id)
          }

        }
      })
      return kolArr ? kolArr.length : 0
    },
    sumFlagVal() {
      let total = 0
      this.dataList.forEach((item) => {
        this.quarterlyMonths.forEach((month) => {
          let flag_value_key = `flag_value_${month}`
          total += (item[flag_value_key] ? item[flag_value_key] / 1 : 0)
        })
      })
      return total
    },
    sumRealVal() {
      let total = 0
      this.dataList.forEach((item) => {
        this.quarterlyMonths.forEach((month) => {
          let flag_value_key = `real_value_${month}`
          total += (item[flag_value_key] ? item[flag_value_key] / 1 : 0)
        })
      })
      return total
    }

    // formQuotientTitle() {
    //   return `批量修改【${ this.formQuotient.year }年${ this.formQuotient.month }月】【${ this.searchCondition.group_name }】营收绩效系数`
    // }
  },
  methods: {
    show(searchCondition) {
      this.searchCondition = searchCondition
      this.editMode = searchCondition.release_status === 'N'
      this.getList()

      if (this.$refs['editPaChief'])
        this.$refs['editPaChief'].show(this.searchCondition)
    },
    changeTableHeight() {
      this.tableHeight = 0
      this.tableHeight = this.$utils.getRecommendedTableHeight('viewBox', 200)
      if (this.tableHeight < 900) {
        this.tableHeight = 900
      }
    },
    valFormat(value) {
      if (value !== '/')
        return numberFormat(value, 2, '.', ',', 'round')

      return value
    },
    moneyFormat(value) {
      if (value !== '/')
        return numberFormat(value, 2, '.', ',', 'round')

      return value
    },
    async saveCell(postData) {
      await this.$api.savePaChiefRevenueData(postData)
    },
    // eslint-disable-next-line
    editVal(row, colName, rowIndex) {
      // console.log(`当前操作第${rowIndex},列${colName},isNaN:${isNaN(row[colName])}`)
      let saveData = {
        year: this.searchCondition.year,
        quarter: this.searchCondition.quarter,
        artist_id: row.artist_id,
        nickname: row.nickname,
        platform_code: row.platform_code,
        income_code: row.income_code,
        platform_name: row.platform_name,
        dept_id: row.dept_id,
        dept_name: row.dept_name
        // income_type: row.income_type,
      }
      const val = row[colName]
      if (colName.indexOf('quotient_') > -1) {
        let arr = colName.split('quotient_')
        let month = Number(arr[1] || 0)
        if (month >= 1 && month <= 12) {
          saveData['month'] = month
          // let syncField = `quotient_${saveData['month']}`
          if (val !== '/') {
            if (!isNaN(val)) {
              saveData['quotient'] = val
              saveData['flag_value'] = val * row['average_value']
              this.dataList[rowIndex][`flag_value_${month}`] = saveData['flag_value']
              this.saveCell(saveData)
            }
          } else {
            saveData['quotient'] = -1
            this.saveCell(saveData)
          }
        }
      } else if (colName.indexOf('flag_value_') > -1) {
        let arr = colName.split('flag_value_')
        let month = Number(arr[1] || 0)
        if (month >= 1 && month <= 12) {
          saveData['month'] = month
          saveData['flag_value'] = val
          this.saveCell(saveData)
        }
      }

      // if (!isNaN(row[colName])) {
      //   row[colName] = row[colName] * 1
      //   //修改系数值-同步计算目标值
      //   if (colName.indexOf('quotient_') > -1) {
      //     let arr = colName.split('_')
      //     let syncField = `flag_value_${arr[1]}`
      //     row[syncField] = row[colName] * row['average_value']
      //   }
      //   //团队奖金数据同步，重新计算表格数据的时候要使用
      //   if (colName.indexOf('amount_') > -1) {
      //     this.bonusRow[colName] = row[colName]
      //     this.saveBonus(row, colName)
      //   } else {
      //     //实时保存数据
      //     this.save(row, colName)
      //   }
      // }

    },
    //保存
    async save(row, colName) {
      await this.$api.saveAPerformanceRevenue({
        search_condition: this.searchCondition,
        data: {
          row: row,
          col_name: colName
        }
      })
    },
    /**
     * 保存-团队奖金
     * @param row
     * @param colName
     * @returns {Promise<void>}
     */
    async saveBonus(row, colName) {
      let arr = colName.split('_')
      let month = arr[1]
      await this.$api.saveBonus({
        search_condition: this.searchCondition,
        data: { type: 'revenue', amount: row[colName], month: month }
      })
    },

    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (this.searchCondition[field])
          condition[field] = this.searchCondition[field]
      })

      return condition
    },
    async getList() {
      this.getCalcFields()
      //当前已核算完成的月份
      // await this.getCheckMonths()
      this.loadingStatus = true
      // this.dataList = []
      let searchCondition = this.handleSearchCondition()
      if (!(searchCondition.year && searchCondition.quarter && searchCondition.dept_id)) {
        // this.$notify.info('请先选择年份、季度、部门')
        return false
      }
      let { list } = await this.$api.getPaChiefRevenueDeptData(searchCondition)
      this.dataList = list || []
      this.loadingStatus = false
      this.forDataList() //合并单元格的数据依据，用于  :span-method="objectSpanMethod"
    },
    forDataList: function() {
      const batch_set_map = {}
      this.dataList.forEach((item, index) => {
        // 合并单元格
        this.rowspan(index)

        // 生成批量设置目标配置
        if (item.platform_code && item.income_code) {
          const key_batch_set_map = `${item.income_code}-${item.platform_code}`
          if (batch_set_map[key_batch_set_map] === undefined) {
            batch_set_map[key_batch_set_map] = {
              platform_name: item.platform_name,
              platform_code: item.platform_code,
              income_code: item.income_code,
              income_type: item.income_type,
              val: null
            }
          }
        }
      })

      this.batchMap.revenue = Object.values(batch_set_map)
      // this.$emit('update:batch-map', Object.values(batch_set_map))
    },

    /**
     *
     * @param index (dataList index)
     */
    rowspan(index) {
      const fields = this.mergeFields //合并的字段
      fields.forEach((field) => {
        if (index === 0) {
          this.spanObj[field] = []
          this.spanObj[field].push(1)
          this.positionObj[field] = 0
        } else {
          // 合并数据：若和上一行的数据相同，则该字段合并行数加1
          if (this.dataList[index][field] === this.dataList[index - 1][field]) {
            if (field === 'platform_name') {
              //平台列，特殊处理
              if (this.dataList[index]['nickname'] === this.dataList[index - 1]['nickname']) {
                this.spanObj[field][this.positionObj[field]] += 1
                this.spanObj[field].push(0)
              } else {
                this.spanObj[field].push(1)
                this.positionObj[field] = index
              }
            } else {
              this.spanObj[field][this.positionObj[field]] += 1
              this.spanObj[field].push(0)
            }

          } else {
            this.spanObj[field].push(1)
            this.positionObj[field] = index
          }
        }
      })
    },
    // 表格合并行

    // eslint-disable-next-line
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      const fields = this.mergeFields
      if (column.property && fields.indexOf(column.property) > -1) {
        const _row = this.spanObj[column.property][rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    getCalcFields() {
      this.calcFields = []
      this.calcFields.push('average_value')
      if (this.quarterlyMonths && this.quarterlyMonths.length > 0) {
        this.quarterlyMonths.forEach((month) => {
          this.bonusRow[`amount_${month}`] = 0//团队奖金行数据初始化

          this.prefixFields.forEach((prefixField) => {
            let field = `${prefixField}${month}`
            this.calcFields.push(field)
          })
        })
      }
    },
    calcCol() {
      let calcRow = {}
      let len = this.dataList.length //10
      if (len > 0) {
        //0~9
        this.dataList.forEach((item) => {
          if (this.calcFields && this.calcFields.length > 0) {
            this.calcFields.forEach((field) => {
              if (field.indexOf('quotient_') === -1) {

                if (!calcRow[field]) {
                  calcRow[field] = 0
                }
                let val = item[field] || 0

                //若考核，才累计（need_check_*）
                let arr = field.split('_')
                let month = parseInt(arr[arr.length - 1])//数据类型：整型
                let needCheckField = `need_check_${month}`
                if (item[needCheckField] === 'N') {
                  return false
                }
                calcRow[field] += val * 1
              }
            })
          }
        })
      }
      return calcRow
    },
    //确认操作
    saveConfirm() {
      let msg = `确认发布【${this.searchCondition.year}年第${this.searchCondition.quarter}季度】【${this.searchCondition.dept_name}】的营收绩效目标？发布后其他人将能看到最新的数据, 是否继续?`

      // let msg = `确认保存【${this.searchCondition.year}】年【${this.searchCondition.group_name}】组第【${this.searchCondition.quarter}】季度的涨粉绩效目标信息, 是否继续?`;
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '再等等',
        type: 'warning'
      }).then(() => {
        this.publishSaved()
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消'
        // });
      })

    },
    //发布已保存到数据
    async publishSaved() {
      this.commitLoading = true
      let info = await this.$api.publishPaChiefRevenueDeptData(this.searchCondition)
      this.commitLoading = false
      if (info === 'ok') {
        this.$notify.success('发布成功')
        this.editStatus = false
        // this.searchCondition.release_status = 'Y'
        await this.getList()
      }
    },
    closeAllMenu() {
      if (this.$refs['refPerformancePlatformsSelect'])
        this.$refs['refPerformancePlatformsSelect'].closeMenu()

      if (this.$refs['refPerformanceSetting'])
        this.$refs['refPerformanceSetting'].closeMenu()
    },
    async rowContextmenu(row, column, e) {
      this.currentRow = row
      this.currentColumn = column
      // console.log(`红人：${row.nickname},平台：${row.platform_name},列：${column.property}`)
      if (this.userPermissions.indexOf('performance_single_setting') > -1) {
        const menuMinWidth = 105
        const offsetWidth = this.$el.offsetWidth // container width
        const maxLeft = offsetWidth - menuMinWidth // left boundary
        e.preventDefault()//阻止元素发生默认的行为
        const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
        const offsetTop = this.$el.getBoundingClientRect().top // container margin left

        const left = e.clientX - offsetLeft // 15: margin right
        if (left > maxLeft) {
          this.left = maxLeft
        } else {
          this.left = left
        }
        const top = e.clientY - offsetTop

        this.closeAllMenu()

        if (['nickname'].indexOf(column.property) > -1) {
          this.$nextTick(() => {
            this.$refs['refPerformancePlatformsSelect'].init(top, left, row)
          })
        } else if (['platform_name'].indexOf(column.property) > -1) {
          this.$nextTick(() => {
            this.$refs['refPerformanceSetting'].init(top, left, row)
          })
        }
        //refPerformanceSetting

      }
    },
    savedPlatformEvent() {
      //修改完红人考核平台
      this.$confirm('红人考核平台已调整，是否刷新数据?', '提示', {
        confirmButtonText: '刷新',
        cancelButtonText: '再等等',
        type: 'warning'
      }).then(() => {
        this.getList()
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      })
    },

    headerContextmenu(column, e) {
      if (this.searchCondition.release_status !== 'N') {
        // 仅支持编辑中的绩效目标支持批量修改
        return false
      }
      this.formQuotient = {}
      e.preventDefault()//阻止元素发生默认的行为
      if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value') > -1) {
        let arr = column.property.split('_')
        // let month = arr[1]performance_single_setting
        this.formQuotient.month = arr[1]
        this.formQuotient.year = this.searchCondition.year
        this.formQuotient.group_id = this.searchCondition.group_id
        this.formQuotient.group_name = this.searchCondition.group_name
        // this.dialogQuotientVisible = true
        if (column.property.indexOf('quotient_') > -1) {
          this.formQuotient.month = arr[1]
          this.formQuotient.type = 'quotient'
        } else {
          this.formQuotient.month = arr[2]
          this.formQuotient.type = 'flagValue'
        }
        const menuMinWidth = 105
        const offsetWidth = this.$el.offsetWidth // container width
        const maxLeft = offsetWidth - menuMinWidth // left boundary
        const offsetLeft = this.$el.getBoundingClientRect().left // container margin left

        let left = e.clientX - offsetLeft // 15: margin right
        if (left > maxLeft) {
          left = maxLeft
        }

        const top = e.layerY + 100
        this.$nextTick(() => {
          if (this.$refs['refPaQuotientEdit'])
            this.$refs['refPaQuotientEdit'].init(top, left, this.formQuotient)
        })
      }
    },
    //============ 批量修改绩效目标或系数 =================
    async updateQuotientCol(formQuotient) {

      let val = formQuotient.data
      let field = formQuotient.type
      let quotient = formQuotient.quotient
      let month = formQuotient.month

      const promises = this.dataList.map((row, rowIndex) => {
        let colName = `quotient_${month}`
        row[colName] = quotient
        // row[`flag_value_${month}`] = quotient * row['average_value']
        let saveData = {
          year: this.searchCondition.year,
          quarter: this.searchCondition.quarter,
          artist_id: row.artist_id,
          nickname: row.nickname,
          platform_code: row.platform_code,
          platform_name: row.platform_name,
          dept_id: row.dept_id,
          dept_name: row.dept_name,
          income_code: row.income_code,
          income_type: row.income_type
        }
        let appendData = {}
        appendData['month'] = month

        if (field === 'quotient') {
          //修改系数列
          if (val !== '/') {
            if (!isNaN(val)) {
              appendData['quotient'] = val
              //目标=系数* 上级季度均值(营收)
              appendData['flag_value'] = val * row['average_value']
              this.dataList[rowIndex][`quotient_${month}`] = appendData['quotient']
              this.dataList[rowIndex][`flag_value_${month}`] = appendData['flag_value']
              return this.saveCell(Object.assign(saveData, appendData))
            }
          } else {
            saveData['quotient'] = -1
            this.dataList[rowIndex][`quotient_${month}`] = appendData['quotient']
            return this.saveCell(Object.assign(saveData, appendData))
          }
        } else if (field === 'flagValue') {
          saveData['quotient'] = -1
          appendData['flag_value'] = val
          this.dataList[rowIndex][`quotient_${month}`] = '/'
          this.dataList[rowIndex][`flag_value_${month}`] = appendData['flag_value']
          return this.saveCell(Object.assign(saveData, appendData))
        } else {
          this.$notify.warning('无效操作')
        }
      })

      await Promise.all(promises)
      this.$message.success('操作成功！')
    },

    editRow(row) {
      this.drawerData.loading_text = '加载中……'
      this.drawerData.title = `${this.searchCondition.year}年第${this.searchCondition.quarter}季度【${this.searchCondition.group_name}】营收数据-录入`
      this.drawerData.visible = true
      this.drawerData.loading = false
      this.$nextTick(() => {
        this.$refs['refPerformanceRevenuePanel'].init(row, { ...this.searchCondition })
      })

    },
    editTable(row, e) {
      if (this.mode === 'drawer') {
        this.editRow(row)
      } else {
        // this.currentRow = row
        const menuMinWidth = 105
        const offsetWidth = this.$el.offsetWidth // container width
        const maxLeft = offsetWidth - menuMinWidth // left boundary
        e.preventDefault()//阻止元素发生默认的行为
        const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
        // const offsetTop = this.$el.getBoundingClientRect().top; // container margin left

        let left = e.clientX - offsetLeft // 15: margin right
        if (left > maxLeft) {
          left = maxLeft
        } else {
          left = left + 100
        }
        const top = 20
        this.$nextTick(() => {
          this.$refs['refPerformanceRevenueEdit'].init(top, left, row, { ...this.searchCondition })
        })
      }


    },
    // 处理抽屉关闭
    drawerClose() {
      this.drawerData.visible = false
      this.drawerData.loading = true
      this.getList()
    },
    showLog() {
      this.logSetting.visible = true
    },
    closeLog() {
      this.logSetting.visible = false
    },
    showSetting() {
      this.performanceSetting.visible = true
    },
    closeSetting() {
      this.performanceSetting.visible = false
      this.getList()
    },
    jumpToDetail(row) {
      const keyword = 'DingTalk'
      if (navigator.userAgent.indexOf(keyword) > -1) {
        // 获取订单配置
        this.$router.push({
          name: 'artist_trend_chart',
          params: { artist_id: row.artist_id },
          query: { group_name: row.group_name }
        })
      } else {
        const { href } = this.$router.resolve({
          name: 'artist_trend_chart',
          params: { artist_id: row.artist_id },
          query: { group_name: row.group_name }
        })
        window.open(href, '_blank')
      }
    },
    //单元格样式调整
    // eslint-disable-next-line
    cellClassName({ row, column, rowIndex, columnIndex }) {
      //月考核：
      let unCheckBool = false
      if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
        let arr = column.property.split('_')
        let month = parseInt(arr[arr.length - 1])//数据类型：整型
        let fieldNeedCheck = `need_check_${month}`
        if (row[fieldNeedCheck] === 'N') {
          unCheckBool = true
          // console.log('fieldNeedCheck', row[fieldNeedCheck])
        }
      }
      if (unCheckBool) {
        return 'performance-uncheck'
      }

      //核算完成：
      let checkBool = false
      let months = this.checkMonthList
      if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
        let arr = column.property.split('_')
        let month = parseInt(arr[arr.length - 1])//数据类型：整型
        if (months.indexOf(month) > -1) {
          checkBool = true
        }
      }

      // console.log(`col:${ column.property },checkBool:${ checkBool }`)
      if (checkBool) {
        return 'performance-checked'
      } else
        return ''

    },
    async changeCheckMonth(month) {

      let params = {
        type: 'revenue',
        group_id: this.searchCondition.group_id,
        year: this.searchCondition.year,
        month: month,
        check_status: this.checkMonthList.indexOf(month) > -1 ? 'Y' : 'N'
      }
      let info = await this.$api.setPerformanceCheckedMonth(params)
      if (info === 'ok') {
        this.$notify.success('操作成功')
      }
    },
    async getCheckMonths() {
      let { months } = await this.$api.getPerformanceCheckedMonths({
        type: 'revenue',
        group_id: this.searchCondition.group_id,
        year: this.searchCondition.year,
        months: this.quarterlyMonths
      })
      //当前已核算完成的月份
      this.checkMonthList = [...months]
    },
    calcBonusClass(val) {
      if (val > 0) {
        return 'bonus-gt0'
      } else
        return ''
    },
    showQuarterReal() {

    },
    changeEditMode() {
      this.editMode = !this.editMode
      if (this.editMode && this.searchCondition.release_status !== 'N') {
        // this.$notify.warning('切到编辑中的数据')
        this.searchCondition.release_status = 'N'
        this.getList()
      }
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 2) {
          sums[index] = '合计'
          return
        }
        if (column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value') > -1) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev * 1 + curr * 1
              } else {
                return prev
              }
            }, 0)
            sums[index] += ''
          } else {
            sums[index] = 'N/A'
          }
          // 格式化数据
          switch (column.property) {
            case 'ratio':
              sums[index] = `${(sums[index])} %`
              break
            case 'amount':
              sums[index] = `￥${this.valFormat(sums[index])}`
              break
            default:

              break
          }
        }
      })

      return sums
    },
    //获取红人集合（用于批量设置限定范围）
    getCurrKolRange() {
      let kolRage = []
      this.dataList.forEach((item) => {
        if (kolRage.indexOf(item.artist_id) === -1)
          kolRage.push(item.artist_id)
      })
      return kolRage
    },
    showBatch() {
      const kolRange = this.getCurrKolRange()
      if (kolRange && kolRange.length > 0) {
        this.$notify.info('批量修改总监营收绩效目标')
        if (this.$refs['refBatchSetPaRevenue'])
          this.$refs['refBatchSetPaRevenue'].show(this.searchCondition, kolRange)
      } else {
        this.$notify.warning('请选选择需要批量设置的红人')
      }

    }
  },
  mounted() {
    //计算高度
    this.changeTableHeight()

    window.removeEventListener('resize', this.changeTableHeight)
    window.addEventListener('resize', this.changeTableHeight)
  }
}
</script>

<style scoped>
.default-edit-icon {
  float: right;
}

.dept {
  color: var(--primary);
  margin-left: 10px;
}
</style>

